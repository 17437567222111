import React, { FC } from 'react';

import FooterMainFragment from 'components/FooterMainFragment/FooterMainFragment';
import { IMenu } from 'models/menu';

interface FooterCompactProps {
  menu: IMenu[];
}

const FooterCompact: FC<FooterCompactProps> = ({ menu }) => {
  return (
    <footer>
      <FooterMainFragment menu={menu} />
    </footer>
  );
};

export default FooterCompact;
