import React, { useEffect, useState, useMemo } from 'react';

import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'universal-cookie';
import getMenu from 'utils/getMenu';

import FooterCompact from 'components/FooterCompact/FooterCompact';
import FooterStandard from 'components/FooterStandard/FooterStandard';
import { useApp } from 'contexts/appContext';
import { FooterType } from 'models/footer';
import { IMenu } from 'models/menu';

import CookieAlert from '../components/CookieAlert/CookieAlert';
import FixedTopBanner from '../components/FixedTopBanner/FixedTopBanner';
import HeaderMenu from '../components/HeaderMenu/HeaderMenu';

const cookies = new Cookies();

const renderFooterByType = (
  menu: IMenu[],
  type?: FooterType,
  hubspotFormID?: string,
  onClickCallToAction?: () => void,
  callToActionDisabled?: boolean,
): JSX.Element => {
  switch (type) {
    case FooterType.COMPACT:
      return <FooterCompact menu={menu} />;
    case FooterType.STANDARD:
    default:
      return (
        <FooterStandard
          callToActionDisabled={callToActionDisabled}
          onClickCallToAction={onClickCallToAction}
          menu={menu}
          hubspotFormID={hubspotFormID}
        />
      );
  }
};

type MainLayoutProps = {
  callToActionDisabled?: boolean;
  onClickCallToAction?(): void;
  className?: string;
  displayFooter?: boolean;
  footerType?: FooterType;
  footerHubspotID?: string;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  callToActionDisabled,
  onClickCallToAction,
  className,
  displayFooter = false,
  footerType,
  footerHubspotID,
}) => {
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [showTopBanner, setShowTopBanner] = useState(false);
  const { donationCampaigns, menuStructure, footerMenuStructure, xmasBanner } =
    useApp();

  useEffect(() => {
    setShowTopBanner(!cookies.get('hide-top-banner'));
    setCookieAccepted(!!cookies.get('slsf-cookie-accept'));
  }, []);

  const handleClose = () => {
    setCookieAccepted(true);
    cookies.set('slsf-cookie-accept', true);
  };

  const handleCloseTopBanner = () => {
    setShowTopBanner(false);
    cookies.set('hide-top-banner', true);
  };

  const menu = useMemo(
    () => getMenu(menuStructure, donationCampaigns),
    [menuStructure, donationCampaigns],
  );
  const footerMenu = useMemo(
    () => [
      ...getMenu(footerMenuStructure),
      {
        label: 'Site Map',
        pathname: '/site-map',
      },
    ],
    [footerMenuStructure],
  );

  return (
    <div className={cx('main-layout flex-column d-flex', className)}>
      {!cookieAccepted && <CookieAlert onClose={handleClose} />}
      <HeaderMenu
        onClickCallToAction={onClickCallToAction}
        callToActionDisabled={callToActionDisabled}
        menu={menu}
      />
      {showTopBanner && xmasBanner && !isEmpty(xmasBanner) && (
        <FixedTopBanner topBanner={xmasBanner} onClose={handleCloseTopBanner} />
      )}
      <div className="main-layout-content flex-grow-1 position-relative">
        {children}
      </div>
      {displayFooter &&
        renderFooterByType(
          footerMenu,
          footerType,
          footerHubspotID,
          onClickCallToAction,
          callToActionDisabled,
        )}
    </div>
  );
};

export default MainLayout;
