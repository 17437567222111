import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CgClose } from 'react-icons/cg';
import { FaChevronDown, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';

import cx from 'classnames';
import mobileAndTabletCheck from 'utils/checkMobile';

import LogoIcon from 'components/Icons/Logo/Logo';
import MenuStructure from 'components/MenuStructure/MenuStructure';
import SearchBar from 'components/SearchBar/SearchBar';
import { IMenu } from 'models/menu';

import UserMenu from '../userMenu';

import s from './HeaderMenu.module.scss';

type HeaderMenuProps = {
  menu: IMenu[];
  callToActionDisabled?: boolean;
  onClickCallToAction?(): void;
};

const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({
  menu,
  callToActionDisabled,
  onClickCallToAction,
}) => {
  const router = useRouter();
  const searchInputRef = useRef<HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState(router.query.query);
  const [searchIsOpened, setSearchIsOpened] = useState(false);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>();
  const secondLevel =
    activeMenuIndex !== undefined ? menu[activeMenuIndex] : undefined;

  const handleSubmitSearch = (value: string) => {
    if (value) {
      router.push(`/search?query=${value}`);
    }
  };

  return (
    <div
      className={cx(
        s['header-menu'],
        { [s.opened]: mobileMenuOpened },
        'header-menu',
      )}
    >
      <div
        className={cx(s['header-menu-primary-bar'], 'py-0 py-md-2')}
        onMouseEnter={() => setActiveMenuIndex(undefined)}
      >
        <Container>
          <nav className="d-flex justify-content-between align-items-center">
            <Link href="/" passHref>
              <a className={s['header-menu-logo']}>
                <LogoIcon />
              </a>
            </Link>
            <div className="d-flex align-items-center">
              <Link href="/signin?createNewAccountTabActive=true" passHref>
                <a
                  className={cx(
                    'btn btn-sm btn-link pr-0 pr-md-2 ',
                    s['header-btn-link'],
                  )}
                  href="https://www.slsfoundation.com.au/"
                >
                  No account? Create one today
                </a>
              </Link>
              <div className="d-md-block d-none">
                <UserMenu />
              </div>
            </div>
          </nav>
        </Container>
      </div>
      <div className={cx(s['header-menu-second-bar'], 'd-flex flex-column')}>
        <div className={cx(s['header-menu-second-bg-primary'], 'py-2')}>
          <Container>
            <div
              className={cx(
                'd-flex justify-content-end justify-content-md-between align-items-center flex-row flex-xl-row flex-wrap flex-xl-nowrap align-items-md-end align-items-xl-center',
                {
                  'flex-md-row pl-9': searchIsOpened,
                  'flex-md-column-reverse': !searchIsOpened,
                },
              )}
            >
              {!searchIsOpened && (
                <nav className="d-md-flex d-none w-100 pl-0 pl-xl-9">
                  {!!menu.length && (
                    <MenuStructure
                      className="d-flex align-items-center"
                      menu={menu}
                      icon={<FaChevronDown className="ml-1" />}
                      menuClass="text-white"
                      activeMenuClass="text-secondary"
                      activeMenuIndex={activeMenuIndex}
                      menuItemProps={{
                        onMouseEnter: (index: number) => {
                          setActiveMenuIndex(index);
                        },
                        onClick: (index: number, ev: MouseEvent) => {
                          if (
                            mobileAndTabletCheck() &&
                            menu[index]?.items?.length
                          ) {
                            setActiveMenuIndex(index);
                            ev.preventDefault();
                          } else {
                            setActiveMenuIndex(undefined);
                          }
                        },
                      }}
                    />
                  )}
                </nav>
              )}
              <div className="d-flex flex-md-shrink-0 flex-shrink-1 flex-grow-0 flex-md-grow-1">
                {searchIsOpened && (
                  <SearchBar
                    onSubmit={handleSubmitSearch}
                    inputProps={{
                      placeholder: 'Search',
                      ref: searchInputRef,
                      autoFocus: true,
                      value: searchTerm,
                      onChange: (value: string) => setSearchTerm(value),
                    }}
                    show={searchIsOpened}
                    className={cx('flex-grow-1')}
                  />
                )}
                <Button
                  onClick={(ev: MouseEvent) => {
                    ev.preventDefault();
                    setSearchIsOpened(!searchIsOpened);
                    setTimeout(() => {
                      if (searchInputRef?.current) {
                        searchInputRef.current?.focus();
                      }
                    });
                  }}
                  variant="link"
                  size="sm"
                >
                  {searchIsOpened ? (
                    <IoMdClose color="white" size={30} />
                  ) : (
                    <FiSearch color="white" size={30} />
                  )}
                </Button>
                {!searchIsOpened && onClickCallToAction && (
                  <Button
                    data-cy="btn-donate-now"
                    variant="danger"
                    className={cx(s['donate-now-btn'], 'flex-shrink-0', {
                      [s.opened]: !callToActionDisabled,
                    })}
                    onClick={onClickCallToAction}
                  >
                    DONATE NOW
                  </Button>
                )}
              </div>
              <Button
                onClick={() => setMobileMenuOpened(!mobileMenuOpened)}
                type="button"
                variant="link"
                aria-label="Toggle navigation"
                className="d-sm-block d-md-none p-2"
              >
                {mobileMenuOpened ? (
                  <CgClose color="yellow" size="20px" />
                ) : (
                  <GiHamburgerMenu color="yellow" size="20px" />
                )}
              </Button>
            </div>
          </Container>
        </div>
        {mobileMenuOpened && (
          <nav
            className={cx(
              s['mobile-navigation'],
              'd-md-none d-flex flex-column flex-grow-1 overflow-auto',
            )}
          >
            <div className="bg-white">
              <Container className="d-flex flex-column p-4">
                <UserMenu signButtonLabel="Sign in to your account" />
                <Link href="/signin" passHref>
                  <a className="text-center mt-2">
                    No account? Create one today
                  </a>
                </Link>
              </Container>
            </div>
            <div className="d-flex flex-nowrap flex-grow-1">
              <MenuStructure
                className="d-flex flex-column flex-shrink-0 p-4 w-100"
                menu={menu}
                menuClass="text-white"
                activeMenuClass="text-secondary"
                activeMenuIndex={activeMenuIndex}
                icon={<FaChevronRight />}
                menuItemProps={{
                  onClick: (index: number, event: Event) => {
                    if (menu[index]?.items?.length) {
                      event.preventDefault();
                    } else {
                      setMobileMenuOpened(false);
                    }
                    setActiveMenuIndex(
                      index === activeMenuIndex ? undefined : index,
                    );
                  },
                }}
              />
              {!!secondLevel?.items?.length && (
                <div
                  className={cx(
                    'bg-white w-100 flex-shrink-0',
                    s['second-level-menu-mobile'],
                  )}
                >
                  <div className="bg-light-grey p-4">
                    <div className="d-flex align-items-center">
                      <Button
                        variant="link"
                        className="py-0"
                        onClick={() => {
                          setActiveMenuIndex(undefined);
                        }}
                      >
                        <FaChevronLeft />
                      </Button>
                      {secondLevel.as &&
                        secondLevel.pathname &&
                        secondLevel.query && (
                          <Link
                            as={secondLevel.as}
                            href={{
                              pathname: secondLevel.pathname,
                              query: secondLevel.query,
                            }}
                          >
                            {/* eslint-disable-next-line */}
                            <a
                              className="font-weight-semi-bold"
                              onClick={() => {
                                setActiveMenuIndex(undefined);
                                setMobileMenuOpened(false);
                              }}
                            >
                              {secondLevel.label}
                            </a>
                          </Link>
                        )}
                    </div>
                    <p className="px-3 pt-2">{secondLevel.description}</p>
                  </div>
                  <div className="p-4">
                    <MenuStructure
                      onClick={() => {
                        setActiveMenuIndex(undefined);
                        setMobileMenuOpened(false);
                      }}
                      multipleLevels
                      menu={secondLevel.items}
                      menuItemProps={{
                        className: 'd-block font-weight-semi-bold py-1 px-3',
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </nav>
        )}
        {!!secondLevel?.items?.length && (
          <div
            className={cx(
              s['second-level-menu'],
              'bg-white mb-n4 d-md-flex d-none',
            )}
            onMouseLeave={() => setActiveMenuIndex(undefined)}
          >
            <Container fluid="lg">
              <Row className="py-0 py-lg-3">
                <Col
                  lg={6}
                  className={cx(
                    s['menu-first-level-description-desktop'],
                    'py-3 pl-4 pl-xl-10',
                  )}
                >
                  {secondLevel.as &&
                    secondLevel.pathname &&
                    secondLevel.query &&
                    secondLevel.label && (
                      <Link
                        as={secondLevel.as}
                        href={{
                          pathname: secondLevel.pathname,
                          query: secondLevel.query,
                        }}
                        passHref
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() => {
                            setActiveMenuIndex(undefined);
                            setMobileMenuOpened(false);
                          }}
                          className="font-weight-semi-bold mb-3 d-block d-xl-none"
                        >
                          {secondLevel.label}
                        </a>
                      </Link>
                    )}
                  <p>{secondLevel.description}</p>
                </Col>
                <Col lg={6} className="py-3">
                  <MenuStructure
                    onClick={() => {
                      setActiveMenuIndex(undefined);
                    }}
                    menu={secondLevel.items}
                    multipleLevels
                    menuItemProps={{
                      className: 'd-block font-weight-semi-bold py-1 px-3',
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderMenu;
