import React, { FunctionComponent } from 'react';
import { Button, Row } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';

import cx from 'classnames';

import s from './CookieAlert.module.scss';

type CookieAlertProps = {
  onClose(): void;
};
const CookieAlert: FunctionComponent<CookieAlertProps> = ({ onClose }) => {
  return (
    <div className={cx(s['cookie-alert'], 'bg-secondary', 'p-1')}>
      <Row className="m-0 justify-content-center align-items-center">
        <p className="mb-0">
          By browsing this site you accept cookies used to improve and
          personalise our services and marketing. Read our updated privacy
          policy for more about how we use your data.
        </p>
        <Button variant="link" onClick={onClose}>
          <div className="d-flex align-items-center">
            Close <BsX />
          </div>
        </Button>
      </Row>
    </div>
  );
};

export default CookieAlert;
