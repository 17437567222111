import React from 'react';

import cx from 'classnames';

const LogoIcon: React.FC<{
  height?: string;
  width?: string;
  className?: string;
}> = props => {
  return (
    <img
      src="/imgs/logo.svg"
      alt="Surf Life Saving Foundation"
      width="74px"
      height="74px"
      style={{
        height: props.height || '74px',
        width: props.width || '74px',
      }}
      {...props}
      className={cx('d-block', props.className)}
    />
  );
};

export default LogoIcon;
