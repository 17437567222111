import React from 'react';
import { Button } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

interface IProps {
  facebookFoundationPage?: string;
  instagramFoundationUser?: string;
  twitterFoundationUser?: string;
  youtubeFoundationChannel?: string;
  buttonProps?: any;
  iconProps?: any;
}
const SocialMediaButtons: React.FC<IProps> = ({
  facebookFoundationPage,
  instagramFoundationUser,
  twitterFoundationUser,
  youtubeFoundationChannel,
  buttonProps,
  iconProps,
}) => {
  return (
    <>
      <Button
        className="p-1"
        as="a"
        href={`https://www.facebook.com/${facebookFoundationPage}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaFacebook color="var(--secondary)" size={30} {...(iconProps || {})} />
      </Button>
      <Button
        className="p-1"
        as="a"
        href={`https://www.instagram.com/${instagramFoundationUser}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaInstagram
          color="var(--secondary)"
          size={30}
          {...(iconProps || {})}
        />
      </Button>
      <Button
        className="p-1"
        as="a"
        href={`https://twitter.com/${twitterFoundationUser}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaTwitter color="var(--secondary)" size={30} {...(iconProps || {})} />
      </Button>
      <Button
        className="p-1"
        as="a"
        href={`https://www.youtube.com/channel/${youtubeFoundationChannel}`}
        target="_blank"
        {...(buttonProps || {})}
      >
        <FaYoutube color="var(--secondary)" size={30} {...(iconProps || {})} />
      </Button>
    </>
  );
};
export default SocialMediaButtons;
