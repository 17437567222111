import Link from 'next/link';
import React, { HTMLProps } from 'react';

import cx from 'classnames';

import { IMenu } from 'models/menu';

interface IProps extends HTMLProps<HTMLElement> {
  menu: IMenu[];
  menuItemProps?: any;
  activeMenuIndex?: number;
  className?: string;
  menuClass?: string;
  activeMenuClass?: string;
  icon?: JSX.Element;
  multipleLevels?: boolean;
}

const MenuStructure: React.FC<IProps> = ({
  menu,
  menuItemProps,
  activeMenuIndex,
  className,
  menuClass,
  activeMenuClass,
  icon,
  multipleLevels,
  ...props
}) => {
  const renderMenu = (
    currentMenu: IMenu[],
    cb?: (nextMenu: IMenu) => JSX.Element | undefined,
  ) => {
    return (
      <ul className={cx('m-0', className)} {...props}>
        {currentMenu.map((menuItem: IMenu, index: number) => {
          // Fix the problem of setting the absolute path
          const isAbsoluteURL =
            (menuItem.query?.slug || '').indexOf('http') !== -1;

          const renderAnchorTag = (anchorProps?: HTMLProps<HTMLElement>) => {
            return (
              <a
                {...menuItemProps}
                {...(anchorProps || {})}
                role="button"
                tabIndex={0}
                className={cx(
                  menuItemProps?.className,
                  'd-flex py-1 px-2 font-weight-semi-bold justify-content-between align-items-center',
                  activeMenuIndex === index ? activeMenuClass : menuClass,
                )}
                onMouseEnter={
                  menuItemProps?.onMouseEnter
                    ? () => {
                        menuItemProps?.onMouseEnter(index);
                      }
                    : undefined
                }
                onKeyPress={
                  menuItemProps?.onClick
                    ? (ev: KeyboardEvent) => {
                        menuItemProps?.onClick(index, ev);
                      }
                    : undefined
                }
                onClick={
                  menuItemProps?.onClick
                    ? (ev: MouseEvent) => {
                        menuItemProps?.onClick(index, ev);
                      }
                    : undefined
                }
              >
                <span>{menuItem.label}</span>
                {!!menuItem?.items?.length && icon}
              </a>
            );
          };

          return (
            <li
              key={menuItem.label}
              id={menuItem.pathname as string}
              className="p-0"
            >
              {isAbsoluteURL ? (
                renderAnchorTag({ href: menuItem.query.slug })
              ) : (
                <Link
                  as={menuItem.as}
                  href={
                    menuItem.href === '/'
                      ? '/'
                      : {
                          pathname: menuItem.pathname,
                          query: menuItem.query,
                        }
                  }
                >
                  {renderAnchorTag()}
                </Link>
              )}
              {cb && !!menuItem?.items?.length && cb(menuItem)}
            </li>
          );
        })}
      </ul>
    );
  };

  return renderMenu(
    menu,
    multipleLevels
      ? (menuItem: IMenu) => {
          return menuItem.items?.length
            ? renderMenu(menuItem.items)
            : undefined;
        }
      : undefined,
  );
};

export default MenuStructure;
