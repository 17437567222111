import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Button } from 'react-bootstrap';

import cx from 'classnames';

import { useAuth } from 'contexts/authContext';

const UserMenu: React.FC<{ dark?: boolean; signButtonLabel?: string }> = (
  { dark, signButtonLabel } = { dark: false },
) => {
  const { user, logout } = useAuth();
  const router = useRouter();

  if (!user || user.isAnonymous) {
    return (
      <Link href="/signin" passHref>
        <Button
          data-cy="btn-sign-in"
          as="a"
          variant={dark ? 'outline-white' : 'outline-primary'}
          size="sm"
        >
          {signButtonLabel || 'Sign in to your account'}
        </Button>
      </Link>
    );
  }

  return (
    <>
      <span className={cx({ 'text-white': dark }, 'text-center')}>
        {(user.displayName && `Hi ${user.displayName}`) || user.email}
      </span>
      <Button
        type="button"
        size="sm"
        variant={dark ? 'outline-white' : 'outline-primary'}
        className="ml-3"
        onClick={async () => {
          await logout();
          router.push('/');
        }}
      >
        Sign out
      </Button>
    </>
  );
};

export default UserMenu;
