import { IHubspotForm } from 'models/hubspotForm';

export interface IFooter {
  id: number;
  name: string;
  hubspot_form_id?: string;
  hubspot_form?: IHubspotForm;
}

export enum FooterType {
  STANDARD = 'standard',
  COMPACT = 'compact',
}
