import { IArticle } from 'models/article';
import { IDonationCampaign } from 'models/donationCampaign';
import { IMenu, IMenuServer } from 'models/menu';

const getMenu = (
  menuStructure: IMenuServer[],
  appeals?: IDonationCampaign[],
  articles?: IArticle[],
): IMenu[] => {
  const LATEST_APPEALS_URL = '/our-appeals';
  const NEWS_URL = '/news';

  const formatMenuStructure = (
    menu: IMenuServer[],
    pathNameNextJS: string,
  ): IMenu[] => {
    return menu.map((menuItem: IMenuServer) => {
      const getItems = ({ href, items }: IMenuServer) => {
        let newPathNameNextJS = '/[slug]';
        switch (href) {
          case LATEST_APPEALS_URL:
            return formatMenuStructure(
              !appeals?.length
                ? []
                : appeals.map((appeal: IDonationCampaign) => ({
                    label: appeal.heading,
                    href: `/campaign/${appeal.slug}`,
                  })),
              '/campaign/[slug]',
            );
          case NEWS_URL:
            if (articles?.length) {
              return formatMenuStructure(
                !articles?.length
                  ? []
                  : articles.map((article: IArticle) => ({
                      label: article.title,
                      href: `/article/${article.slug}`,
                    })),
                '/campaign/[slug]',
              );
            }
            newPathNameNextJS = '/article/[slug]';
            break;
          default:
        }
        return items?.length
          ? formatMenuStructure(items, newPathNameNextJS)
          : undefined;
      };

      return {
        ...menuItem,
        as: menuItem.href,
        pathname: pathNameNextJS,
        items: getItems(menuItem),
        query: menuItem.href
          ? { slug: menuItem.href.replace(/^\//, '') }
          : undefined,
      };
    });
  };

  return formatMenuStructure(menuStructure, '/[slug]');
};
export default getMenu;
