import React, { FormEvent, useRef } from 'react';
import { Form } from 'react-bootstrap';

import cx from 'classnames';

import s from './SearchBar.module.scss';

interface IProps {
  onSubmit: (value: string) => void;
  className?: string;
  inputProps?: any;
  show?: boolean;
}
const SearchBar: React.FC<IProps> = (
  { onSubmit, inputProps, ...props } = { inputProps: {}, onSubmit: () => {} },
) => {
  const searchInputRef = useRef(null);

  const handleSubmitSearch = (ev: FormEvent<HTMLElement>) => {
    ev.preventDefault();
    onSubmit(inputProps.value);
  };

  return (
    <Form onSubmit={handleSubmitSearch} action="/search" {...props}>
      <input
        className={cx('form-control', s['search-bar-input'])}
        ref={searchInputRef}
        name="query"
        {...inputProps}
        onChange={({ target: { value } }) =>
          inputProps.onChange && inputProps.onChange(value)
        }
      />
    </Form>
  );
};

export default SearchBar;
