import React from 'react';

import cx from 'classnames';

type CircleAvatarOverflowProps = {
  size?: string;
  className?: string;
  offset?: number;
  wrapperProps?: any;
};
const CircleAvatarOverflow: React.FC<CircleAvatarOverflowProps> = ({
  children,
  size,
  className,
  offset,
  wrapperProps,
  ...props
}) => {
  const { className: wrapperClassName, ...restWrapperProps } =
    wrapperProps || {};
  return (
    <>
      <style jsx>
        {`
          @keyframes slideUp {
            0% {
              margin-top: 50px;
            }
          }

          .circle-avatar-overflow {
            position: relative;
            box-shadow: 0 0 0 ${size === 'sm' ? 2 : 4}px white,
              0 5px 15px 3px rgba(0, 0, 0, 0.2);
          }
          .circle-avatar-overflow .circle-top,
          .circle-avatar-overflow .circle-back {
            position: absolute;
            left: 0;
            top: 0;
          }

          .circle-avatar-overflow .circle-wrapper {
            animation: slideUp forwards 0.4s ease;
            transform: scale(1.25) translateY(-${offset || 20}px);
            transform-origin: top;
          }

          .circle-avatar-overflow .circle-top .circle-wrapper {
            height: ${size ? 60 : 40}%;
            overflow: hidden;
          }
        `}
      </style>
      <div
        className={cx(
          'circle',
          'circle-avatar-overflow',
          { [`circle-${size}`]: size },
          className,
        )}
        {...props}
      >
        <div
          className={cx(
            'circle',
            'circle-top',
            {
              [`circle-${size}`]: size,
            },
            wrapperClassName,
          )}
          {...restWrapperProps}
        >
          <div className="circle-wrapper">{children}</div>
        </div>
        <div
          role="presentation"
          className={cx(
            'circle overflow-hidden',
            'circle-back',
            {
              [`circle-${size}`]: size,
            },
            wrapperClassName,
          )}
          {...restWrapperProps}
        >
          <div className="circle-wrapper">{children}</div>
        </div>
      </div>
    </>
  );
};

export default CircleAvatarOverflow;
