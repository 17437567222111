import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';

import cx from 'classnames';

import CircleAvatarOverflow from 'components/CircleAvatarOverflow/CircleAvatarOverflow';
import { ITopBanner } from 'models/topBanner';

import s from './FixedTopBanner.module.scss';

type FixedTopBannerProps = {
  topBanner: ITopBanner;
  onClose?(): void;
};
const FixedTopBanner: FunctionComponent<FixedTopBannerProps> = ({
  topBanner,
  onClose,
}) => {
  const { asPath } = useRouter();
  const { displayPages, message, linkText, link } = topBanner;
  const [bannerHeight, setBannerHeight] = useState<number | undefined>();
  const topBannerRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    const height = topBannerRef.current?.clientHeight;
    return setBannerHeight(height);
  }, []);
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  if (!displayPages.includes(asPath)) {
    return <></>;
  }

  return (
    <>
      <div className={cx(s.root, 'bg-secondary p-1')} ref={topBannerRef}>
        <Row className="align-items-center">
          <Col md={8} className="offset-md-3 d-flex">
            <CircleAvatarOverflow
              size="sm"
              offset={10}
              className="bg-white mt-2 mr-3 flex-shrink-0"
            >
              <img
                loading="lazy"
                src="/imgs/xmas-tree-avatar.svg"
                alt="xmas tree"
                width={30}
              />
            </CircleAvatarOverflow>

            <div className="pt-2">
              {message}{' '}
              <Link href={link || ''}>
                <span className={cx('text-dark', s.link)}>{linkText}</span>
              </Link>
            </div>
          </Col>
          <Col md={1}>
            <Button variant="link" onClick={onClose}>
              <BsX />
            </Button>
          </Col>
        </Row>
      </div>
      <div style={{ paddingTop: bannerHeight }} />
    </>
  );
};

export default FixedTopBanner;
