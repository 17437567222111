import getConfig from 'next/config';
import React from 'react';
import HubspotForm from 'react-hubspot-form';

import cx from 'classnames';

import s from './HubspotFormWithStyle.module.scss';

const { publicRuntimeConfig } = getConfig();

interface IProps {
  theme?: string;
  formId: string;
  onSubmit?: () => void;
  loading?: JSX.Element;
}
const HubspotFormWithStyle: React.FC<IProps> = ({
  theme,
  formId,
  onSubmit,
  loading,
}) => {
  return (
    <div
      id={`hubspot-form-${formId}`}
      className={cx(s[`${theme || 'light'}-theme`], s['hubspot-form'])}
    >
      <HubspotForm
        portalId={publicRuntimeConfig.hubspotPortalID}
        formId={formId}
        onSubmit={onSubmit}
        loading={loading || <div>Loading...</div>}
      />
    </div>
  );
};

export default HubspotFormWithStyle;
